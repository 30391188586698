import React from "react"
import { BlogPost } from "../components/BlogPost"
import Layout from "../components/Layout"
import { useStoryblok } from "../storyblok"

export default function BlogEntry({ pageContext, location }) {
  const story = useStoryblok(pageContext.story)

  return (
    <Layout location={location}>
      <BlogPost story={story} />
    </Layout>
  )
}
